import { httpApps, httpDealer, httpAdmin } from "../helper/http-common";

class SelectYourDiamoiondServices {
  GetRingBuilderOpeningPage(inputData) {
    return httpApps.post("RingBuilder/GetRingBuilderOpeningPage", inputData);
  }
  GetRingBuilderSelectLabDiamond(inputData) {
    return httpApps.post(
      "RingBuilder/GetRingBuilderSelectLabDiamond",
      inputData
    );
  }

  GetRingBuilderSelectMinedDiamond(inputData) {
    return httpApps.post(
      "RingBuilder/GetRingBuilderSelectMinedDiamond",
      inputData
    );
  }

  GetAvailableRingbuilderFilters(inputData) {
    return httpApps.post(
      "RingBuilder/GetAvailableRingbuilderFilters",
      inputData
    );
  }

  SendMailToRetailerForNoResults(inputData) {
    return httpApps.post(
      "RingBuilder/SendMailToRetailerForNoResults",
      inputData
    );
  }
  GetRingBuilderMinedCompareData(inputData) {
    return httpApps.post(
      "RingBuilder/GetRingBuilderMinedCompareData",
      inputData
    );
  }

  GetRingBuilderLabCompareData(inputData) {
    return httpApps.post("RingBuilder/GetRingBuilderLabCompareData", inputData);
  }

  RingBuilderRequestCertificate(inputData) {
    return httpApps.post(
      "RingBuilder/RingBuilderRequestCertificate",
      inputData
    );
  }

  GetDealerInformation(inputData) {
    return httpApps.post("DiamondLink/GetDealerInformation", inputData);
  }
  GetDealerInformationJewelry(inputData) {
    return httpApps.post("DiamondLink/GetDealerInformationJewelry", inputData);
  }

  GetSimilarDiamondsByPage(inputData, pageName) {
    if (pageName == "Mined") {
      return httpApps.post(
        "RingBuilder/GetRingBuilderSelectMinedDiamond",
        inputData
      );
    } else {
      return httpApps.post(
        "RingBuilder/GetRingBuilderSelectLabDiamond",
        inputData
      );
    }
  }

  GetRingBuilderViewDetailsMineLabDiamond(inputData) {
    return httpApps.post(
      "RingBuilder/GetRingBuilderViewDetailsMineLabDiamond",
      inputData
    );
  }

  GetDealerLocationData(inputData) {
    return httpApps.post("DiamondLink/GetDealerLocationData", inputData);
  }
  ProductTracking(inputData) {
    return httpApps.post("DiamondLink/ProductTracking", inputData);
  }
  DiamondTracking(inputData) {
    return httpApps.post("DiamondLink/DiamondTracking", inputData);
  }
  GetMenuList(inputData) {
    return httpAdmin.post("/Tools/GetMenuListForML", inputData);
  }
  CompanyWhileLabel() {
    return httpDealer.get("/Dashboard/CompanyWhileLabelForML");
  }
  GetLanguage(inputData) {
    return httpAdmin.post(
      "/SelectLenguage/SelectlanguageForDelarForML",
      inputData
    );
  }
}

export default new SelectYourDiamoiondServices();
